import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FaSun, FaMoon } from "react-icons/fa"
import Carousel from "@components/Carousel"
import { size } from "../util/breakpoints"

const ButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eee;
  margin: 0px 20px 0 0;
  font-size: 50px;

  @media (max-width: ${size.laptopL}) {
    svg {
      font-size: 0.7em !important;
    }
  }

  @media (max-width: ${size.mobileL}) {
    margin: 0.2em 0.4em 0.2em 0;
    padding: 0.15em;

    svg {
      font-size: 0.6em !important;
    }
  }

  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  svg {
    font-size: 0.7em;
  }
`
const BackgroundStyle = styled.div`
  background-color: #124054;
  background-image: url("https://www.transparenttextures.com/patterns/asfalt-dark.png");
`

const TopBarStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 30px 60px 0px 60px;
  background: #124054;

  @media (max-width: ${size.laptop}) {
    padding: 30px 40px 30px 40px;
  }

  @media (max-width: ${size.mobileL}) {
    padding: 8px 30px 8px 30px;
  }

  .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;

    a {
      padding: 0 !important;
      display: flex;
      align-self: center;
    }

    @media (max-width: ${size.laptop}) {
      padding: 10px 0 20px 0;
    }

    @media (max-width: ${size.mobileL}) {
      padding: 0;
    }
  }

  .group {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 !important;

    @media (max-width: ${size.laptop}) {
      justify-content: center;
    }

    p.title {
      font-size: 56px !important;
      margin: 0 20px !important;
      padding: 40px 0 !important;

      @media (max-width: ${size.laptop}) {
        font-size: 3.5vw !important;
      }

      @media (max-width: ${size.tablet}) {
        font-size: 7.5vw !important;
        padding: 20px 0 !important;
        margin: 0 10px !important;
      }
    }

    img {
      width: 65px;
      margin: 0;
      border-radius: 10px;

      @media (max-width: ${size.laptop}) {
        width: 45px !important;
        margin: 0 !important;
      }

      @media (max-width: ${size.tablet}) {
        width: 8vw !important;
        margin: 0 !important;
      }

      @media (max-width: ${size.mobileM}) {
        width: 12vw !important;
        margin: 0 !important;
      }
    }
  }
`

const BackButtonStyle = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0 !important;
    font-size: 26px !important;
    padding: 0 !important;
    color: #fff;
  }

  &:hover {
    opacity: 0.6;
    color: #fff !important;
  }
`

const FilterStyle = styled.h4`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0px auto;
  padding: 30px 50px 30px 50px;
  align-items: center;

  &.sub {
    border-top: 2px solid #999;
    background: rgba(255, 255, 255, .1) !important;
  }

  @media (max-width: ${size.laptopL}) {
    padding: 0.5em 0;
    justify-content: center;
  }

  @media (max-width: ${size.tablet}) {
    padding: 0.25em;
    justify-content: center;
    margin: 0em;
  }

  .filter {
    position: relative;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 35px;
    font-size: 30px;
    border-radius: 8px;
    margin: 5px;
    background: transparent;
    color: #fff !important;
    font-weight: normal !important;
    transition-duration: .3s;

    &.sub {
      font-size: 26px !important;
      padding: 5px 40px;
      min-width: 200px;
      background: transparent;
      color: #fff !important;
      font-weight: normal !important;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: #50cdff !important;
        opacity: 0.99 !important;
        background: transparent;
      }
    }

    @media (max-width: ${size.laptopL}) {
      padding: 0.5em;
      font-size: 26px;

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: ${size.tablet}) {
      padding: 0.25em 0.4em;
      margin: 3px;
      font-size: 26px;
      
      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: ${size.mobileL}) {
      padding: 0 0.1em;
      font-size: 0.8em;
      width: 30%;
      
      &:hover {
        cursor: pointer;
      }
    }

    &.active {
      color: #fefefe !important;
      opacity: 0.99 !important;
      background: rgba(42, 172, 224, .5);
      svg {
        color: #071115;
        font-size: 1em;
      }
    }

    &:hover {
      color: #50cdff !important;
      // background: rgba(42, 172, 224, .5) !important;
      opacity: 0.4;
      cursor: pointer;
      color: #fff:
    }
  }
`

const categories = [
  {
    name: "all",
    displayName: "All",
    regex:
      "/(apparel)|(headwear)|(bags)|(drinkware)|(shirts)|(jackets)|(fleece)|(other)|(caps)|(beanies)|(backpacks)|(coolers)|(duffels)|(totes)|(miscbags)|(bottles)|(mugs)|(tumblers)|(tech)|(miscellaneous)/",
    filter: 0,
    subcategories: [],
    main: true,
  },
  {
    name: "apparel",
    displayName: "Apparel",
    regex: "/(shirts)|(jackets)|(fleece)|(other)/",
    filter: 1,
    subcategories: ["shirts", "jackets", "fleece", "other"],
    main: true,
  },
  {
    name: "shirts",
    displayName: "Shirts",
    regex: "/(shirts)/",
    filter: 1,
    subcategories: [],
    main: false,
  },
  {
    name: "jackets",
    displayName: "Jackets",
    regex: "/(jackets)/",
    filter: 1,
    subcategories: [],
    main: false,
  },
  {
    name: "fleece",
    displayName: "Fleece",
    regex: "/(fleece)/",
    filter: 1,
    subcategories: [],
    main: false,
  },
  {
    name: "other",
    displayName: "Other",
    regex: "/(other)/",
    filter: 1,
    subcategories: [],
    main: false,
  },
  {
    name: "bags",
    displayName: "Bags",
    regex: "/(backpacks)|(coolers)|(duffels)|(totes)|(miscbags)/",
    filter: 3,
    subcategories: ["backpacks", "coolers", "duffels", "totes", "miscbags"],
    main: true,
  },
  {
    name: "backpacks",
    displayName: "Backpacks",
    regex: "/(backpacks)/",
    filter: 3,
    main: false,
  },
  {
    name: "coolers",
    displayName: "Cooler Bags",
    regex: "/(coolers)/",
    filter: 3,
    main: false,
  },
  {
    name: "duffels",
    displayName: "Duffel Bags",
    regex: "/(duffels)/",
    filter: 3,
    main: false,
  },
  {
    name: "totes",
    displayName: "Tote Bags",
    regex: "/(totes)/",
    filter: 3,
    main: false,
  },
  {
    name: "miscbags",
    displayName: "Misc. Bags",
    regex: "/(miscbags)/",
    filter: 3,
    main: false,
  },
  {
    name: "drinkware",
    displayName: "Drinkware",
    regex: "/(bottles)|(mugs)|(tumblers)/",
    filter: 4,
    subcategories: ["bottles", "mugs", "tumblers"],
    main: true,
  },
  {
    name: "bottles",
    displayName: "Bottles",
    regex: "/(bottles)/",
    filter: 4,
    main: false,
  },
  {
    name: "mugs",
    displayName: "Mugs",
    regex: "/(mugs)/",
    filter: 4,
    main: false,
  },
  {
    name: "tumblers",
    displayName: "Tumblers",
    regex: "/(tumblers)/",
    filter: 4,
    main: false,
  },
  {
    name: "headwear",
    displayName: "Headwear",
    regex: "/(caps)|(beanies)/",
    filter: 2,
    subcategories: ["beanies", "caps"],
    main: true,
  },
  {
    name: "caps",
    displayName: "Caps",
    regex: "/(caps)/",
    filter: 2,
    subcategories: [],
    main: false,
  },
  {
    name: "beanies",
    displayName: "Beanies",
    regex: "/(beanies)/",
    filter: 2,
    subcategories: [],
    main: false,
  },
  {
    name: "tech",
    displayName: "Tech",
    regex: "/(tech)/",
    filter: 5,
    subcategories: [],
    main: true,
  },
  {
    name: "miscellaneous",
    displayName: "Miscellaneous",
    filter: 6,
    subcategories: [],
    main: true,
  },
]

export default function Gallery() {
  const [filter, setFilter] = useState({ name: "all" })
  const [dark, setDark] = useState(false)

  function selectFilter(f) {
    setFilter(f)
  }

  return (
    <BackgroundStyle>
      <TopBarStyle
        style={{
          color: "#fff",
          background: dark ? "#071115" : "#186887",
        }}
      >
        <div className="buttons">
          <Link to="/" style={{ textDecoration: "none" }}>
            <BackButtonStyle>
              <p>← Go Back</p>
            </BackButtonStyle>
          </Link>
          <ButtonStyle>
            {!dark ? (
              <FaSun onClick={() => setDark(true)} />
            ) : (
              <FaMoon onClick={() => setDark(false)} />
            )}
          </ButtonStyle>
        </div>
        <div className="group">
          <img src={"/Icon.png"} alt="fs" />
          <p className="title">Merchandise Gallery</p>
        </div>
      </TopBarStyle>
      <FilterStyle style={{ background: !dark ? "#186887" : "#071115" }}>
        {categories
          .filter(f => f.main)
          .map((c, i) => {
            return (
              <div
                key={i}
                style={{ color: !dark ? "#2aace0" : "#071115" }}
                className={`filter${
                  filter.name === c.name ||
                  c.subcategories.includes(filter.name)
                    ? " active"
                    : ""
                }`}
                onClick={() => selectFilter(c)}
              >
                {c.displayName}
              </div>
            )
          })}
      </FilterStyle>
      {[1, 2, 4].includes(filter.filter) && (
        <FilterStyle
          className=" sub"
          style={{ background: !dark ? "#186887" : "#071115" }}
        >
          {categories
            .filter(f => !f.main && f.filter === filter.filter)
            .map((c, i) => {
              return (
                <div
                  key={i}
                  style={{ color: !dark ? "#2aace0" : "#071115" }}
                  className={`filter${
                    filter.name === c.name ? " active sub" : " sub"
                  }`}
                  onClick={() => selectFilter(c)}
                >
                  {c.displayName}
                </div>
              )
            })}
        </FilterStyle>
      )}
      <Carousel filter={filter} dark={dark} all={filter.name === "all"} />
    </BackgroundStyle>
  )
}
