import React from "react"
import styled from "styled-components"
import GatsbyImage from "gatsby-image"
import { size } from "../util/breakpoints"

const ModalStyle = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999999;

  &.dark {
    background-color: rgba(0, 0, 0, 0.9) !important;
  }

  &.light {
    background-color: rgba(125, 125, 125, 0.95) !important;
  }

  .modal-content {
    position: relative;
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-image {
    z-index: 99999;
    width: 50%;
    border-radius: 8px; /* Optional rounded corners */

    @media (max-width: ${size.laptop}) {
      width: 60%;
    }
  }

  .modal-close {
    color: #1697c9;
    display: flex;
    align-items: center;
    position: absolute;
    top: 50px;
    right: 50px;
    margin: 20px auto;
    background: none;
    border: none;
    font-size: 70px;
    cursor: pointer;
    z-index: 99999999999999999999;
    transition: transform 0.3s ease;
    opacity: 0.5;

    @media (max-width: ${size.laptop}) {
      font-size: 45px;
    }

    &:hover {
      transform: scale(1.3);
      opacity: 1;
    }
  }
`

const Modal = ({ image, onClose, isOpen, dark }) => {
  if (!isOpen) return null
  if (!image) return null

  console.log(dark)

  return (
    <ModalStyle className={dark ? "dark" : "light"} onClick={() => onClose()}>
      <div className="modal-content">
        <img
          className="mark"
          src="/logo-white.png"
          width="80%"
          style={{
            position: "absolute",
            top: "10%",
            opacity: 0.05,
            margin: "0 10%",
          }}
        />
        <GatsbyImage
          className="modal-image"
          alt="Enlarged view"
          durationFadeIn={400}
          fadeIn
          fluid={image.childImageSharp.fluid}
        />
        {/* <button className="modal-close" onClick={() => onClose()} title="Close">
          ✖
        </button> */}
      </div>
    </ModalStyle>
  )
}

export default Modal
