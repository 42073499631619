import React, { useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import { size } from "../util/breakpoints"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import ScrollToTopButton from "@components/ScrollToTopButton"
import Modal from "@components/Modal"

const CarouselStyle = styled.div`
  position: relative;
  margin: 0px;
  min-height: 80vh !important;
  padding: 1em 1em 20em 1em;
  background: transparent;
  background-image: url("https://www.transparenttextures.com/patterns/asfalt-dark.png");
`

const ImageStyle = styled.div`
  margin: 0 auto;
  display: grid;
  gap: 10px;
  background: transparent;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${size.laptopL}) {
    grid-template-columns: repeat(3, 1fr);

    img {
      padding: 20px !important;
    }
  }

  @media (max-width: ${size.laptop}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${size.tabtop}) {
    grid-template-columns: repeat(2, 1fr);

    img {
      padding: 10px !important;
    }
  }

  @media (max-width: ${size.mobileM}) {
    grid-template-columns: repeat(1, 1fr);
  }

  img {
    padding: 30px;
  }

  .image-box {
    &:hover {
      transform: translateY(-25px);
      transition-duration: 0.5s;
      transform: scale(1.2);
    }
  }

  :hover {
    cursor: pointer;
  }
`

export default function Carousel({ filter, dark, all }) {
  const [isOpen, setOpen] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [filteredItems, setFilteredItems] = useState([])
  const [shuffledItems, setShuffledItems] = useState([])

  const { allFile } = useStaticQuery(graphql`
    query allFile {
      allFile(
        filter: {
          extension: { regex: "/(png)|(jpg)/" }
          dir: {
            regex: "/(apparel)|(headwear)|(bags)|(drinkware)|(shirts)|(jackets)|(fleece)|(other)|(caps)|(beanies)|(backpacks)|(coolers)|(duffels)|(totes)|(miscbags)|(bottles)|(mugs)|(tumblers)|(tech)|(miscellaneous)/"
          }
        }
      ) {
        edges {
          node {
            id
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (allFile && allFile.edges.length > 0) {
      if (filter.filter === 0 || all) {
        setFilteredItems(allFile.edges)
      } else if (filter.subcategories && filter.subcategories.length > 0) {
        const filtered = allFile.edges.filter(f =>
          filter.subcategories.includes(f.node.relativeDirectory)
        )
        setFilteredItems(filtered)
      } else {
        setFilteredItems(
          allFile.edges.filter(f => f.node.relativeDirectory === filter.name)
        )
      }
    }
    console.log(filteredItems)
  }, [filter, allFile]) // Dependency on filter and allFile

  useEffect(() => {
    setShuffledItems(shuffle([...filteredItems])) // Shuffle after filtering
  }, [filteredItems]) // Dependency on filteredItems

  // Shuffle function
  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }

  const handleImageClick = image => {
    if (currentImage !== image) {
      setCurrentImage(image) // Set the new image
      setOpen(true) // Open modal
    } else {
      setOpen(true)
    }
  }

  const handleImageBoxClick = image => () => {
    handleImageClick(image)
  }

  const handleCloseModal = () => {
    setOpen(false) // Close modal
  }

  const shuffledItemsMemo = useMemo(() => {
    return shuffle(filteredItems)
  }, [filteredItems]) // Only shuffle when filteredItems change  // Ensure shuffledItems is only updated once

  useEffect(() => {
    if (filteredItems.length > 0 && shuffledItems.length === 0) {
      setShuffledItems(shuffledItemsMemo) // Set shuffled items initially
    }
  }, [filteredItems, shuffledItemsMemo])

  return (
    <CarouselStyle
      style={{
        backgroundColor: dark ? "#333" : "#aaa",
      }}
    >
      <Modal
        image={currentImage}
        isOpen={isOpen}
        onClose={handleCloseModal}
        dark={dark}
      />
      <ScrollToTopButton />
      {shuffledItems.length > 0 && (
        <ImageStyle>
          {shuffledItems.map(image => (
            <div
              className="image-box"
              key={Math.random()}
              onClick={handleImageBoxClick(image.node)}
            >
              <GatsbyImage
                key={Math.random()}
                className=""
                durationFadeIn={700}
                fadeIn
                fluid={image.node.childImageSharp.fluid}
                alt={image.node.relativeDirectory}
              />
            </div>
          ))}
        </ImageStyle>
      )}
    </CarouselStyle>
  )
}
